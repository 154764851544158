html, body, #root, .app {
	display: flex;
	flex-direction: column;
	height: 100%;
}

body, #root, .app {
	flex-grow: 1;
    overflow: hidden;
}

.wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	width: 100%;
}
.map {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
}
.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &__title {
        &_404 {
            font-size: calc(60px + (130 - 60) * (100vw / 1920));
            line-height: 120%;
            text-align: center;
            padding-right: 20%;
            margin: 0;
        }
    }
    &__description {
        &_404 {
            font-size: calc(26px + (52 - 26) * (100vw / 1920));
            line-height: 2;
            text-align: center;
            padding-left: 20%;
            margin: 0 0 6%;
        }
    }
}
.header {
    background-color: $green;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    padding: 20px 28px;

    height: 57px;
    width: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    padding: 20px 28px;
    &_404 {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &_addplace {
        padding: 0;
        height: 100%;
    }
}

.link {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;

    color: $dark;
    margin-bottom: 15px;

    &_white {
        color: $white;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.overlay {
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -20;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    &.active {
        transition: opacity .3s ease-out;
        opacity: 0.7;
        z-index: 20;
    }
}

.btn {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    border-radius: 24px;
    padding: 10px 14px;
    text-decoration: none;
    &_green {
        color: $white;
        background-color: $green;
    }
}

.iframe {
    width: 100%;
    height: 100%;
}

.about {
    max-width: 214px;
}