.sidebar {
    background-color: $white;
    box-shadow: 0 0 18px 2px rgba(0, 0, 0, .7);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
    width: 270px;
    z-index: 5;

    &_description {
        width: 50%;
    }
}

.section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    &__info {
        color: $gray;
        padding: 30px 28px;
        p {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 15px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.footer {
    background-color: $light;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 30px 28px;
    margin-top: auto;
}

.logo {
    display: flex;
    width: max-content;
    max-width: 128px;
    &__img {
        width: 100%;
    }
}

.scroll {
    flex-grow: 1;
    overflow-y: auto;
    &::-webkit-scrollbar-button {
        background-image:url('');
        background-repeat:no-repeat;
        width:5px;
        height:0px
    }
        
    &::-webkit-scrollbar-track {
        background-color:#ecedee
    }
        
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background-color: rgba($green, .8);
    }
        
    &::-webkit-scrollbar-thumb:hover{
        background-color: $green;
    }
        
    &::-webkit-resizer{
        background-image:url('');
        background-repeat:no-repeat;
        width:4px;
        height:0px
    }
        
    &::-webkit-scrollbar{
        width: 4px;
    }
}

@media (max-width: 768px) {
    .section {
        background-color: transparent;
        position: fixed;
        width: 100%;
        z-index: 2;
        &_top {
            flex-shrink: 0;
            flex-grow: 0;
            top: 0;
        }
        &_bottom {
            flex-shrink: 0;
            flex-grow: 0;
            bottom: 0;
        }
        &.opened {
            position: relative;
        }
    }

    .mobile-bar {
        background-color: $white;
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0;
        bottom: 0;
        z-index: 20;
        width: 100%;
        .section {
            position: static;
        }
    }
}