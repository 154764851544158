$main-font: 'Roboto', sans-serif;

$white: #FFFFFF;
$black: #000000;

$dark: #343434;
$gray: #9A9A9A;
$green: #1BB876;

$border-color: #E5E5E5;

$light: #FBFBFB;
