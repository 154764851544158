body {
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
	background-repeat: no-repeat;

	&::before,
	&::after {
		box-sizing: inherit;
	}
}