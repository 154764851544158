.tooltip {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 4px;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  min-height: 142px;
  width: 138px;
  z-index: 10;

  &__header {
    color: $white;
    background-color: $green;

    font-weight: 900;
    font-size: 14px;
    line-height: 16px;

    padding: 24px 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__title {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__body {
    background-color: $white;
    font-size: 12px;
    line-height: 12px;
    padding: 24px 12px;
  }

  &__box {
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    color: $dark;
    font-weight: 700;
    margin: 0 0 6px;
  }

  &__text {
    color: $gray;
    margin: 0;
  }
}